@use '@carbon/layout';
@use '@carbon/react';
@use '@carbon/type';
@use '@carbon/styles';
@use '@carbon/styles/scss/grid';
@use '@carbon/colors';

@include type.reset();
@include type.default-type();
@include type.type-classes();
@include grid.flex-grid();

@import './base/typo';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

#main-content {
  margin-left: 0px !important;

  @media (min-width: 1055px) {
    margin-left: 256px !important;
  }
}

.cds--side-nav__link {
  color: colors.$gray-70;

  &:hover {
    color: colors.$black;
  }
}

.margin-bottom-3 {
  margin-bottom: layout.$spacing-03;
}

.margin-bottom-5 {
  margin-bottom: layout.$spacing-05;
}

.margin-bottom-6 {
  margin-bottom: layout.$spacing-06;
}

.margin-bottom-7 {
  margin-bottom: layout.$spacing-07;
}

.font-gray {
  color: rgb(56, 56, 56);
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.link {
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
  }
}

table {
  text-align: start;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid colors.$gray-30;
}

thead {
  border-bottom: 1px solid colors.$gray-30;
  text-transform: uppercase;

  th,
  td {
    padding: 6px 12px;
  }
}

tfoot {
  border-top: 1px solid colors.$gray-30;
}

th,
td {
  padding: 12px;

  .font-bold {
    color: rgb(80, 79, 79);
    font-weight: 600;
  }
}

.text-align-end {
  text-align: end;
}

.text-align-start {
  text-align: start;
}

.text-align-center {
  text-align: center;
}

.text-decoration-underline {
  text-decoration: underline;
}

.cds--btn {
  align-items: center;
}